import React from 'react'
import { SECTION_MARGIN } from '../../../utils/constants'
import { Box, Button, Flex, Heading, Text } from '../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { SectionProductValues } from '../../../types/sections'
import { GatsbyImage } from 'gatsby-plugin-image'

function ProductValues(props: SectionProductValues): JSX.Element {
  const { id, sectionId, headline, productValues, buttons } = props

  return (
    <Box position={'relative'} as={'section'} id={sectionId ?? id} my={SECTION_MARGIN}>
      <Grid>
        <Box position={'relative'}>
          <Row center={'xs'}>
            <Col xs={12} sm={10} md={8} lg={7}>
              <Heading as={'h2'} type={'heading1'} textAlign={'center'}>
                {headline}
              </Heading>
            </Col>
          </Row>

          <Box my={[40, 40, 64]}>
            <Row style={{ gap: '32px 0' }} center={'xs'}>
              {productValues.map((pv) => (
                <Col key={pv.id} xs={12} md={4}>
                  <Flex
                    flexDirection={'column'}
                    alignItems={'stretch'}
                    justifyContent={'space-between'}
                    p={[20, 20, 24]}
                    backgroundColor={'white'}
                    boxShadow={'card'}
                    borderColor={'black'}
                    borderStyle={'solid'}
                    borderWidth={'1px'}
                    borderRadius={8}
                    height={'100%'}
                  >
                    <Box flex={'1'}>
                      {pv.asset && (
                        <Box mb={5} borderRadius={8} overflow={'hidden'}>
                          <GatsbyImage alt={pv.asset?.alt ?? pv.title} image={pv.asset?.gatsbyImageData} />
                        </Box>
                      )}
                      <Heading as={'h3'} type={'heading4'} textAlign={'center'}>
                        {pv.title}
                      </Heading>

                      {pv.description && (
                        <Box mt={2}>
                          <Text textAlign={'center'}>{pv.description}</Text>
                        </Box>
                      )}
                    </Box>

                    {pv.buttons.length > 0 && (
                      <Flex mt={4} justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'} rowGap={4}>
                        {pv.buttons.map((button) => (
                          <Box key={button.id}>
                            <Button key={button.id} {...button} />
                          </Box>
                        ))}
                      </Flex>
                    )}
                  </Flex>
                </Col>
              ))}
            </Row>
          </Box>

          <Row>
            <Col xs={12}>
              {buttons.length > 0 && (
                <Flex justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'} rowGap={4}>
                  {buttons.map((button) => (
                    <Box key={button.id}>
                      <Button key={button.id} {...button} />
                    </Box>
                  ))}
                </Flex>
              )}
            </Col>
          </Row>
        </Box>
      </Grid>
    </Box>
  )
}

export default ProductValues
